<template>
  <div>
    <template-table
      ref="table"
      :column="column"
      :data="data"
      :paging="paging"
      :tableAction="false"
      :selectAction="newCustomer"
      :searchAction="false"
      @sortBy="sortRequest"
      @select="select"
    >
      <template v-slot:body="{ item }">
        <td>
          <div class="d-flex align-items-center">
            <avatar
              size="40px"
              :text="item.fullName"
              :src="item.imageUrl && item.imageUrl.url"
              :rounded="true"
            />
            <div class="d-flex flex-column ml-5 cell-overflow">
              <p class="mb-0 text-overflow">
                {{ item.fullName }}
              </p>
              <p class="mb-0">
                {{ item.activePackageName }}
              </p>
            </div>
          </div>
        </td>
        <td style="width: 20px">
          <action-dropdown
            :value="item"
            :show_copy="false"
            :show_view="false"
            :show_edit="false"
            :show_delete="false"
            :boundary="data.length > 2 ? 'scrollParent' : 'window'"
          >
            <template v-if="!isMultipleSelect">
              <template v-if="!isNoAccount(item.status) && item.isViewUser">
                <b-dropdown-text
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="goToUserDashboard(item)"
                >
                  <a class="navi-link text-primary">
                    <b-icon-grid class="mr-2" scale="1.2" />
                    <span class="navi-text text-primary"
                      >Xem user dashboard</span
                    >
                  </a>
                </b-dropdown-text>
                <b-dropdown-divider v-if="item.isViewUser"></b-dropdown-divider>
                <b-dropdown-text
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="goToUserApp(item)"
                >
                  <a class="navi-link text-primary">
                    <span class="menu-icon svg-icon svg-icon-sm">
                      <inline-svg
                        class="svg-icon"
                        src="/media/svg/icons/Neolex/Basic/user-info.svg"
                      />
                    </span>
                    <span class="navi-text text-primary"
                      >Xem chi tiết user app</span
                    >
                  </a>
                </b-dropdown-text>
              </template>
              <template v-if="!item.statusTag">
                <template v-if="newCustomer">
                  <b-dropdown-divider
                    v-if="!isNoAccount(item.status) && isWritePermission"
                  ></b-dropdown-divider>
                  <template
                    v-if="isNoAccount(item.status) && isWritePermission"
                  >
                    <b-dropdown-text
                      tag="div"
                      class="navi-item cursor-pointer"
                      @click="createAccount(item)"
                    >
                      <a class="navi-link text-primary">
                        <span class="menu-icon svg-icon svg-icon-sm">
                          <inline-svg
                            class="svg-icon"
                            src="/media/svg/icons/Neolex/Basic/user-plus.svg"
                          />
                        </span>
                        <span class="navi-text text-primary"
                          >Tạo tài khoản</span
                        >
                      </a>
                    </b-dropdown-text>
                    <b-dropdown-divider></b-dropdown-divider>
                  </template>
                  <template
                    v-if="isWaitingActive(item.status) && isWritePermission"
                  >
                    <b-dropdown-text
                      tag="div"
                      class="navi-item cursor-pointer"
                      @click="activePackage(item)"
                    >
                      <a class="navi-link text-primary">
                        <span class="menu-icon svg-icon svg-icon-sm">
                          <inline-svg
                            class="svg-icon"
                            src="/media/svg/icons/Neolex/Basic/check-square.svg"
                          />
                        </span>
                        <span class="navi-text text-primary"
                          >Kích hoạt gói</span
                        >
                      </a>
                    </b-dropdown-text>
                    <b-dropdown-divider />
                  </template>
                  <template v-if="isActived(item.status) && isWritePermission">
                    <b-dropdown-text
                      tag="div"
                      class="navi-item cursor-pointer"
                      @click="schedule(item)"
                    >
                      <a class="navi-link text-primary">
                        <span class="menu-icon svg-icon svg-icon-sm">
                          <inline-svg
                            class="svg-icon"
                            src="/media/svg/icons/Neolex/Time/calendar.svg"
                          />
                        </span>
                        <span class="navi-text text-primary"
                          >Đặt lịch đầu vào</span
                        >
                      </a>
                    </b-dropdown-text>
                  </template>
                  <b-dropdown-text
                    v-if="isShowDelete(item.status) && isWritePermission"
                    tag="div"
                    class="navi-item cursor-pointer"
                    @click="deleteCustomer(item)"
                  >
                    <a class="navi-link text-danger">
                      <span class="menu-icon svg-icon svg-icon-sm">
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/power.svg"
                        />
                      </span>
                      <span class="navi-text text-danger">Xóa dữ liệu</span>
                    </a>
                  </b-dropdown-text>
                </template>
                <template v-else>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-text
                    v-if="isWaitingCoach(item.status) && isWritePermission"
                    tag="div"
                    class="navi-item cursor-pointer"
                    @click="perform(item)"
                  >
                    <a class="navi-link text-primary">
                      <span class="menu-icon svg-icon svg-icon-sm">
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/settings.svg"
                        />
                      </span>
                      <span class="navi-text text-primary">Thực hiện</span>
                    </a>
                  </b-dropdown-text>
                  <b-dropdown-text
                    v-if="
                      isWaitingAssignGroup(item.status) && isWritePermission
                    "
                    tag="div"
                    class="navi-item cursor-pointer"
                    @click="assign(item)"
                  >
                    <a class="navi-link text-primary">
                      <span class="menu-icon svg-icon svg-icon-sm">
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/users.svg"
                        />
                      </span>
                      <span class="navi-text text-primary">Phân nhóm</span>
                    </a>
                  </b-dropdown-text>
                </template>
              </template>
              <template>
                <b-dropdown-divider
                  v-if="
                    item.statusTag ===
                      ACTIVE_ACCOUNT_STATUS_TAG.BOUGHT_NEW_PACKAGE &&
                    isWritePermission
                  "
                />
                <b-dropdown-text
                  v-if="
                    item.statusTag ===
                      ACTIVE_ACCOUNT_STATUS_TAG.BOUGHT_NEW_PACKAGE &&
                    isWritePermission
                  "
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="activePackage(item)"
                >
                  <a class="navi-link text-primary">
                    <span class="menu-icon svg-icon svg-icon-sm">
                      <inline-svg
                        class="svg-icon"
                        src="/media/svg/icons/Neolex/Basic/check-square.svg"
                      />
                    </span>
                    <span class="navi-text text-primary">Kích hoạt gói</span>
                  </a>
                </b-dropdown-text>
              </template>
            </template>
            <template v-else>
              <template v-if="isSelectStatusTag">
                <b-dropdown-text
                  v-if="
                    item.statusTag ===
                      ACTIVE_ACCOUNT_STATUS_TAG.BOUGHT_NEW_PACKAGE &&
                    isWritePermission
                  "
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="activePackage(item)"
                >
                  <a class="navi-link text-primary">
                    <span class="menu-icon svg-icon svg-icon-sm">
                      <inline-svg
                        class="svg-icon"
                        src="/media/svg/icons/Neolex/Basic/check-square.svg"
                      />
                    </span>
                    <span class="navi-text text-primary">Kích hoạt gói</span>
                  </a>
                </b-dropdown-text>
                <b-dropdown-text v-else>
                  <a class="navi-link">
                    <span class="navi-text">Nothing actions</span>
                  </a>
                </b-dropdown-text>
              </template>
              <template v-else>
                <template v-if="isSelectAccountType && isWritePermission">
                  <b-dropdown-text
                    tag="div"
                    class="navi-item cursor-pointer"
                    @click="createAccount(item)"
                  >
                    <a class="navi-link text-primary">
                      <span class="menu-icon svg-icon svg-icon-sm">
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/user-plus.svg"
                        />
                      </span>
                      <span class="navi-text text-primary">Tạo tài khoản</span>
                    </a>
                  </b-dropdown-text>
                  <b-dropdown-divider />
                </template>
                <template v-if="isSelectActivePackageType && isWritePermission">
                  <b-dropdown-text
                    tag="div"
                    class="navi-item cursor-pointer"
                    @click="activePackage(item)"
                  >
                    <a class="navi-link text-primary">
                      <span class="menu-icon svg-icon svg-icon-sm">
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/check-square.svg"
                        />
                      </span>
                      <span class="navi-text text-primary">Kích hoạt gói</span>
                    </a>
                  </b-dropdown-text>
                  <b-dropdown-divider />
                </template>
                <template
                  v-if="
                    isSelectActivePackageAndAccountType && isWritePermission
                  "
                >
                  <b-dropdown-text
                    tag="div"
                    class="navi-item cursor-pointer"
                    @click="deleteCustomer()"
                  >
                    <a class="navi-link text-danger">
                      <span class="menu-icon svg-icon svg-icon-sm">
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/power.svg"
                        />
                      </span>
                      <span class="navi-text text-danger">Xóa dữ liệu</span>
                    </a>
                  </b-dropdown-text>
                </template>
                <b-dropdown-text v-else>
                  <a class="navi-link">
                    <span class="navi-text">Nothing actions</span>
                  </a>
                </b-dropdown-text>
              </template>
            </template>
          </action-dropdown>
        </td>
        <td>
          {{ item.phoneNumber }}
        </td>
        <td v-if="newCustomer">
          {{ convertTimestampToDate(item.createDatetime) }}
        </td>
        <td>{{ convertTimestampToDate(item.activeDate) }}</td>
        <td>{{ item.packageName }}</td>
        <td>{{ convertTimestampToDate(item.boughtDate) }}</td>
        <td>
          <span
            v-if="item.statusTag"
            class="status__tag"
            :class="getClassByStatusTag(item.statusTag)"
            >{{ getStatusTag(item.statusTag) }}</span
          >
          <div class="status" :class="getClassByStatus(item.status)">
            {{ getStatus(item.status) }}
          </div>
        </td>
        <td v-if="newCustomer" class="cell-overflow">
          <div class="text-overflow">
            {{ item.importUserName }}
          </div>
        </td>
        <td v-else>{{ getAppointmentDateTime(item) }}</td>
      </template>
    </template-table>
  </div>
</template>
<script>
import { ACTIVE_ACCOUNT_STATUS } from '@/core/plugins/constants';
import { ACTIVE_ACCOUNT_STATUS_TAG } from '../../../../core/plugins/constants';
export default {
  name: 'TableCustomer',
  props: {
    paging: {
      type: Object,
      default: () => ({
        page: 1,
        pageSize: 10,
        total: 0,
      }),
    },
    data: {
      type: Array,
      default: () => [],
    },
    sort: {
      type: Object,
      default: () => ({
        by: null,
        order: null,
      }),
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedItems: [],
      ACTIVE_ACCOUNT_STATUS_TAG,
    };
  },
  computed: {
    newCustomer() {
      return this.type === 'NEW';
    },
    column() {
      if (this.newCustomer) {
        return [
          {
            key: 'fullName',
            label: 'Khách hàng',
            sortable: true,
            style: {
              width: '300px',
              'min-width': '200px',
            },
          },
          {
            key: 'actionDropdown',
            label: '',
            sortable: false,
          },
          {
            key: 'numberPhone',
            label: 'Số điện thoại',
            sortable: false,
            style: {
              'min-width': '115px',
              width: '120px',
            },
          },
          {
            key: 'createDatetime',
            label: 'Ngày thêm',
            sortable: true,
            style: {
              'min-width': '125px',
              width: '130px',
            },
          },
          {
            key: 'activeDate',
            label: 'Ngày kích hoạt',
            sortable: true,
            style: {
              'min-width': '150px',
              width: '150px',
            },
          },
          {
            key: 'package',
            label: 'Gói mua',
            sortable: false,
            style: {
              'min-width': '160px',
              width: '150px',
            },
          },
          {
            key: 'boughtDate',
            label: 'Ngày mua',
            sortable: true,
            style: {
              'min-width': '120px',
              width: '130px',
            },
          },
          {
            key: 'status',
            label: 'Trạng thái',
            sortable: false,
            style: {
              width: '165px',
              'min-width': '165px',
            },
          },
          {
            key: 'updatedPerson',
            label: 'Người import',
            sortable: false,
          },
        ];
      }
      return [
        {
          key: 'fullname',
          label: 'Khách hàng',
          sortable: true,
          style: {
            width: '350px',
            'min-width': '250px',
          },
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
        {
          key: 'numberPhone',
          label: 'Số điện thoại',
          sortable: false,
          style: {
            'min-width': '115px',
            width: '150px',
          },
        },
        {
          key: 'activedate',
          label: 'Ngày kích hoạt',
          sortable: true,
          style: {
            'min-width': '150px',
            width: '150px',
          },
        },
        {
          key: 'package',
          label: 'Gói mua',
          sortable: false,
          style: {
            'min-width': '160px',
            width: '150px',
          },
        },
        {
          key: 'boughtdate',
          label: 'Ngày mua',
          sortable: true,
          style: {
            'min-width': '120px',
            width: '130px',
          },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          style: {
            width: '165px',
            'min-width': '165px',
          },
        },
        {
          key: 'appointmentTime',
          label: 'Giờ hẹn',
          sortable: true,
          style: {
            width: '165px',
            'min-width': '185px',
          },
        },
      ];
    },
    isMultipleSelect() {
      return this.selectedItems.length >= 2;
    },
    isSelectAccountType() {
      return (
        this.selectedItems.filter(
          (el) => el.status === ACTIVE_ACCOUNT_STATUS.NO_ACCOUNT,
        ).length === this.selectedItems.length
      );
    },
    isSelectActivePackageType() {
      return (
        this.selectedItems.filter(
          (el) => el.status === ACTIVE_ACCOUNT_STATUS.WAITING_ACTIVE,
        ).length === this.selectedItems.length
      );
    },
    isSelectActivePackageAndAccountType() {
      return (
        this.selectedItems.filter(
          (el) =>
            el.status === ACTIVE_ACCOUNT_STATUS.WAITING_ACTIVE ||
            el.status === ACTIVE_ACCOUNT_STATUS.NO_ACCOUNT,
        ).length === this.selectedItems.length
      );
    },
    isSelectStatusTag() {
      return (
        this.selectedItems.filter(
          (el) => el.statusTag === ACTIVE_ACCOUNT_STATUS_TAG.BOUGHT_NEW_PACKAGE,
        ).length === this.selectedItems.length
      );
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  methods: {
    sortRequest(val) {
      this.$emit('sort', val);
    },
    schedule(item) {
      this.$emit('schedule', item);
    },
    assign(item) {
      this.$emit('assign', item);
    },
    perform(item) {
      this.$emit('perform', item);
    },
    select(params) {
      this.selectedItems = params;
    },
    createAccount(customer) {
      this.$emit('create-account', {
        selectedCustomers: this.selectedItems,
        customer,
      });
    },
    activePackage(item) {
      this.$emit('active-package', {
        selectedCustomers: this.selectedItems,
        customer: item,
      });
    },
    deleteCustomer(item) {
      this.$swal({
        title: '',
        text: `Bạn có chắc muốn xóa dữ liệu này không?`,
        icon: '/media/svg/icons/SweetAlert/alert-triangle-red.svg',
        buttons: {
          cancel: {
            text: 'Quay lại',
            value: false,
            visible: true,
            className: 'btn btn-secondary',
            closeModal: true,
          },
          confirm: {
            text: `Xóa`,
            value: true,
            visible: true,
            className: `btn btn-inactive`,
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.$emit('delete', item ? [item] : this.selectedItems);
          }
        }.bind(this),
      );
    },
    getStatus(status) {
      switch (status) {
        case ACTIVE_ACCOUNT_STATUS.NO_ACCOUNT:
          return 'Chưa tạo tài khoản';
        case ACTIVE_ACCOUNT_STATUS.WAITING_ACTIVE:
          return 'Chờ kích hoạt';
        case ACTIVE_ACCOUNT_STATUS.ACTIVED:
          return 'Đã kích hoạt';
        case ACTIVE_ACCOUNT_STATUS.WAITING_COACHING:
          return 'Chờ coaching';
        case ACTIVE_ACCOUNT_STATUS.WAITING_ASSIGN_GROUP:
          return 'Chờ phân nhóm';

        default:
          break;
      }
    },
    getClassByStatus(status) {
      switch (status) {
        case ACTIVE_ACCOUNT_STATUS.NO_ACCOUNT:
          return 'no-account';
        case ACTIVE_ACCOUNT_STATUS.WAITING_ACTIVE:
        case ACTIVE_ACCOUNT_STATUS.WAITING_COACHING:
          return 'wating-active';
        case ACTIVE_ACCOUNT_STATUS.ACTIVED:
        case ACTIVE_ACCOUNT_STATUS.WAITING_ASSIGN_GROUP:
          return 'active';

        default:
          break;
      }
    },
    getClassByStatusTag(statusTag) {
      if (statusTag === ACTIVE_ACCOUNT_STATUS_TAG.BOUGHT_NEW_PACKAGE) {
        return 'new__package';
      }

      return 'waiting';
    },
    getStatusTag(statusTag) {
      if (statusTag === ACTIVE_ACCOUNT_STATUS_TAG.BOUGHT_NEW_PACKAGE) {
        return 'Đã mua gói mới';
      }

      return 'Chờ hiệu lực';
    },
    isNoAccount(status) {
      return status === ACTIVE_ACCOUNT_STATUS.NO_ACCOUNT;
    },
    isWaitingActive(status) {
      return status === ACTIVE_ACCOUNT_STATUS.WAITING_ACTIVE;
    },
    isActived(status) {
      return status === ACTIVE_ACCOUNT_STATUS.ACTIVED;
    },
    isShowDelete(status) {
      return (
        status === ACTIVE_ACCOUNT_STATUS.NO_ACCOUNT ||
        status === ACTIVE_ACCOUNT_STATUS.WAITING_ACTIVE
      );
    },
    isWaitingCoach(status) {
      return status === ACTIVE_ACCOUNT_STATUS.WAITING_COACHING;
    },
    isWaitingAssignGroup(status) {
      return status === ACTIVE_ACCOUNT_STATUS.WAITING_ASSIGN_GROUP;
    },
    resetSelect() {
      this.$refs['table'].resetSelect();
    },
    getAppointmentDateTime(item) {
      if (!item.calendar) return null;

      const appointmentDate = this.formatDateLocale(
        item.calendar.appointmentDate * 1000,
      );
      const fromTime = this.getTimeOfDate({
        date: item.calendar.appointmentDate * 1000,
      });
      const toTime = this.getTimeOfDate({
        date: (item.calendar.appointmentDate + item.calendar.duration) * 1000,
      });

      return `${appointmentDate} ${fromTime}-${toTime}`;
    },
    goToUserDashboard(item) {
      this.$router.push({
        name: 'user_dashboard',
        params: {
          id: item.patientId,
        },
      });
    },
    goToUserApp(item) {
      this.$router.push({
        name: 'user_app_view',
        params: {
          id: item.patientId,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-overflow {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.status {
  padding: 6px 12px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  font-size: 12px;

  &.active {
    background: #21a567;
  }

  &.no-account {
    background: #ff5756;
  }

  &.wating-active {
    background: #f58220;
  }
}

.status__tag {
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;

  &.waiting {
    color: #f58220;
  }

  &.new__package {
    color: #ff5756;
  }
}
</style>
